import React, { useEffect, useState } from "react"
import { Select, Button  } from 'antd';
import Prismic from 'prismic-javascript';

import Layout from "../components/layout"
import SEO from "../components/seo"

import OccassionList from "../components/occassionList"

const prismicURL = 'https://bijouterie-lunas.cdn.prismic.io/api/v2';

const Occassion = () => {
	const [ categories, setCategories ] = useState(null);
	const [ allProducts, setAllProducts ] = useState(null);
	const [ products, setProducts ] = useState(null);
	const [ filters, setFilters ] = useState([]);

	const updateFilters = (value, index) => {
		let tmp = [ ...filters ];
		tmp[index] = value;
		setFilters(tmp);
	};

	const filterProducts = () => {
		let prods = [ ...allProducts ];

		filters.forEach(f => {
			if (f !== 'all')
				prods = prods.filter(p => p.filters.includes(f));
		});

		setProducts(prods);
	};

	useEffect(() => {
		if (products !== null)
			filterProducts();
	}, [filters])

	useEffect(() => {
		Prismic.getApi(prismicURL).then((api) => {
			return api.query(
				Prismic.Predicates.at('document.type', 'filters'),
				{ pageSize : 100 }
			)
		})
			.then(async response => {
				let cats = response.results.map(i => ({
					name: i.data.name,
					options: []
				}));

				let filters = [];

				for (let i = 0; i < response.results.length; i++) {
					const cat = response.results[i];

					filters.push('all');

					const getOption = id => Prismic.getApi(prismicURL).then((api) => {
						return api.query(
							Prismic.Predicates.at('document.id', id),
							{ pageSize : 100 }
						)
					})
						.then(res => ({ id: res.results[0].id, name: res.results[0].data.name }));

					for (let j = 0; j < cat.data.options.length; j++) {
						let option = await getOption(cat.data.options[j].option.id);
						cats[i].options.push(option);
					}
				}

				setFilters(filters);
				setCategories(cats);
			});

		Prismic.getApi(prismicURL).then((api) => {
			return api.query(
				Prismic.Predicates.at('document.type', 'products'),
				{ pageSize : 100 }
			)
		})
			.then(response => {
				let prods = response.results.map(i => ({ ...i.data, id: i.id }));
				prods.forEach((p, i) => {
					prods[i].filters = prods[i].filters.map(o => o.option.id);
				});

				prods.forEach((p, i) => {
					if (p.images.length === 1 && typeof p.images[0].img.url === 'undefined') {
						prods[i].images = [ prods[i].image ];
					}
					else {
						prods[i].images = [ prods[i].image ].concat(prods[i].images);
					}
				});

				setAllProducts(prods);
				setProducts(prods);
			});
	}, []);


	return (
        <>
			<Layout>
				<SEO title="Ventes d'occasion" />

				<div className="slideshow">
					<div className="usp">
						<div  className="usp-txt-header" style={{fontSize: '6em'}}>Montres neuves et d'occasion</div>
					</div>
				</div>

				<div className="border-gold-bottom" />

				<h2 className="h2-mobile"  style={{ marginTop: 40 }}>Montres neuves et d'occasion</h2>

				<section>
					<p className="p-2 p-font" style={{textAlign: 'center', fontWeight: '800', fontSize: '1.4 rem', padding: '0', margin: '0'}}>
						Retrouvez ici nos montres toutes marques neuves et d’occasion.<br/>
						Recherchez-vous un modèle particulier? Contactez-nous!
					</p>
				</section>

				{categories !== null && products !== null && (
					<section>
						<div className="search-barre" style={{display: 'flex', justifyContent: 'center', margin: '0 auto' }}>
							{categories.map((category, index) => (
								<div key={category.name} className="sct-mobile-search" style={{ flex: '1 1 auto', display: 'flex', alignItems: 'center', margin: '0 10px' }}>
									<strong style={{ flex: '0 1 auto', marginRight: 10 }}>{category.name} : </strong>

									<Select
										value={filters[index]}
										onChange={value => updateFilters(value, index)}
										dropdownMatchSelectWidth={false}
										dropdownStyle={{ minWidth: 240 }}
										style={{ flex: '1 0 auto' }}
									>
										<Select.Option value="all">Tous</Select.Option>
										{category.options.map(option => (
											<Select.Option key={option.id} value={option.id}>{option.name}</Select.Option>
										))}
									</Select>
								</div>
							))}

							{/*<Button className="button-primary button-search"  style={{height: '36px', paddingTop: '6px'}} onClick={filterProducts}>Rechercher</Button>*/}

						</div>

						<OccassionList products={products} />
					</section>
				)}
			</Layout>
        </>
	)
}

export default Occassion

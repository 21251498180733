import React, { useRef, useState } from "react"
import { Col, Icon, Row, Carousel, Button, Tag, Modal } from "antd"
import PrismicDOM from "prismic-dom"
import ContactForm from "./ContactForm"


const ModalOccasion = ({ product }) => {
  const carouselRef = useRef(null)
  const [bookModal, setBookModal] = useState(false)

  return product ? (
    <div style={{ marginBottom: "100px" }}>
      <div style={{ color: "#222", fontSize: "2em" }}>{product.name}</div>
      <div style={{ alignItems: "center", borderTop: "2px solid #a8a294" }}>
        <div style={{ display: "flex", justifyContent: "center", paddingTop: 10, paddingBottom: 10 }}>
          <div style={{ position: "relative", width: "50vh", height: "50vh", textAlign: "center" }}>
            {product.images.length > 1 &&
            <Icon type="left" style={{ position: "absolute", top: "50%", left: -10, marginTop: -16, fontSize: 32 }}
                  onClick={() => carouselRef.current.prev()}/>}
            <Carousel ref={carouselRef}>
              {product.images.map((img, i) => img.img ? (
                <img
                  key={i}
                  className="img img-ratio desktop-occassion"
                  style={{
                    width: "40vw",
                    height: "40vw",
                    maxWidth: "512px",
                    maxHeight: "512px",
                  }}
                  src={img.img.url}
                />
              ) : (
                <img
                  key={i}
                  className="img img-ratio desktop-occassion"
                  style={{
                    width: "40vw",
                    height: "40vw",
                    maxWidth: "512px",
                    maxHeight: "512px",
                  }}
                  src={img.url}
                />
              ))}
            </Carousel>
            {product.images.length > 1 &&
            <Icon type="right" style={{ position: "absolute", top: "50%", right: -10, marginTop: -16, fontSize: 32 }}
                  onClick={() => carouselRef.current.next()}/>}
          </div>
        </div>
        <Row style={{
          display: "flex", justifyContent: "space-around", padding: "0px",
        }}>
          <Col span={32} className="desktop-occassion">
            <table>
              <tbody>
              <tr>
                <td style={{ color: "#333", fontWeight: "bold", width: "13vw" }}>Marque :</td>
                <td>{product.name}</td>
              </tr>
              <tr>
                <td style={{ color: "#333", fontWeight: "bold" }}>Référence :</td>
                <td>{product.reference}</td>
              </tr>
              <tr>
                <td style={{ color: "#333", fontWeight: "bold" }}>Année :</td>
                <td>{product.date}</td>
              </tr>
              <tr>
                <td style={{ color: "#333", fontWeight: "bold" }}>État :</td>
                <td>{product.state}</td>
              </tr>
              <tr>
                <td style={{ color: "#333", fontWeight: "bold" }}>Contenu :</td>
                <td style={{ paddingTop: "30px" }}
                    dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(product.content) }}/>
              </tr>
              {product.stock ? <tr>
                <td style={{ color: "#333", fontWeight: "bold" }}>Prix :</td>
                <td>
                  <strong>{product.price > 1000 ? ((product.price + "").replace(new RegExp(`^(.{${(product.price + "").length - 3}})(.)`), `$1'$2`)) : product.price}</strong> CHF <span
                  style={{ fontSize: "0.7em" }}> TTC ( TVA 7,7% comprise)</span></td>
              </tr> : ""}
              <tr>
                <td style={{ color: "#333", fontWeight: "bold" }}>Disponibilité :</td>
                <td>{product.stock ? <Tag color="#87d068" style={{ fontSize: "0.86em" }}> En Stock</Tag> :
                  <Tag color="#a65c5c" style={{ fontSize: "0.86em" }}> épuisé </Tag>}</td>
              </tr>
              </tbody>
            </table>

            <div style={{ textAlign: "center" }}>
              {product.stock ? <Button className="button-gold-box" style={{
                height: "56px",
                margin: "30px auto 0 auto",
                fontSize: "2em",
                paddingTop: "6px",
              }} onClick={() => setBookModal(true)}>Réserver</Button> : ""}
            </div>
          </Col>
        </Row>

        <div className="mobile-occassion">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #aaa", padding: 4 }}>
              <span style={{ color: "#333", fontWeight: "bold", flex: "0 1 40%"}}>Marque :</span>
              <span style={{ flex: "1 1 auto", textAlign: "right" }}>{product.name}</span>
            </div>
            <div
              style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #aaa", padding: 4 }}>
              <span style={{ color: "#333", fontWeight: "bold", flex: "0 1 40%"}}>Référence :</span>
              <span style={{ flex: "1 1 auto", textAlign: "right" }}>{product.reference}</span>
            </div>
            <div
              style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #aaa", padding: 4 }}>
              <span style={{ color: "#333", fontWeight: "bold", flex: "0 1 40%" }}>Année :</span>
              <span style={{ flex: "1 1 auto", textAlign: "right" }}>{product.date}</span>
            </div>
            <div
              style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #aaa", padding: 4 }}>
              <span style={{ color: "#333", fontWeight: "bold", flex: "0 1 40%" }}>État :</span>
              <span style={{ flex: "1 1 auto", textAlign: "right" }}>{product.state}</span>
            </div>
            {product.content.length > 0 && (
              <div
                style={{ display: "flex", borderBottom: "1px solid #aaa", padding: 4 }}>
                <span style={{ color: "#333", fontWeight: "bold", flex: "0 1 40%" }}>Contenu :</span>
                <span className="p-product-content" style={{ letterSpacing: '1px', flex: "1 1 auto", textAlign: "right" }}><small>{product.content[0].text}</small></span>
              </div>
            )}
            {product.description.length > 0 && (
              <div
                style={{ display: "flex", borderBottom: "1px solid #aaa", padding: 4 }}>
                <span style={{ color: "#333", fontWeight: "bold", flex: "0 1 40%" }}>Commentaire :</span>
                <span className="p-product-content" style={{ letterSpacing: '1px', flex: "1 1 auto", textAlign: "right" }}><small>{product.description[0].text}</small></span>
              </div>
            )}
						{product.stock ? <div
							style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #aaa", padding: 4 }}>
								<span style={{ color: "#333", fontWeight: "bold", flex: "0 1 40%" }}>
									Prix :
								</span>
								<span style={{ flex: "1 1 auto", textAlign: "right" }}>
									<span style={{float: 'right', fontSize: '1.0em'}}><strong>{product.price > 1000 ? ((product.price + "").replace(new RegExp(`^(.{${(product.price + "").length - 3}})(.)`), `$1'$2`)) : product.price} </strong>
									CHF</span><br /><span style={{ fontSize: "0.7em" }}> TTC ( TVA 7,7% comprise)</span>
								</span>
						</div> : ""}
            <div
              style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #aaa", padding: 4 }}>
              <span style={{ color: "#333", fontWeight: "bold", flex: "0 1 40%"}}>Disponibilité :</span>
              <span style={{ maxWidth: '50vw', flex: "1 1 auto", textAlign: "right" }}>{product.stock ? <Tag color="#87d068"> En Stock</Tag> :
                <Tag color="#a65c5c" style={{ fontSize: "0.86em" }}> épuisé </Tag>}</span>
            </div>
          </div>

          <div style={{ textAlign: "center" }}>
            {product.stock ? <Button className="button-gold-box" style={{
              height: "56px",
              margin: "30px auto 0 auto",
              fontSize: "2em",
              paddingTop: "6px",
            }} onClick={() => setBookModal(true)}>Réserver</Button> : ""}
          </div>

        </div>
      </div>

      <Modal
        style={{ minWidth: "40vw", height: "80vh" }}
        visible={bookModal}
        onOk={() => setBookModal(false)}
        onCancel={() => setBookModal(false)}
        okButtonProps={{ style: { display: "none" } }}
      >

        <h3 style={{ paddingTop: 0, marginTop: 0 }}>{product.name}</h3>

        <ContactForm product={product}/>

      </Modal>
    </div>
  ) : null
}

export default ModalOccasion

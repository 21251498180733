import React, { useState } from "react"
import { Modal, Card , Icon, Tag } from "antd"

import ModalOccasion from "./modalOccassion"









const OccassionList = ({ products }) => {
	const [ showModal, setShowModal ] = useState(null);
	const { Meta } = Card;

	return (
		<>
			<div style={{ display: 'flex', justifyContent: 'center', padding: 20, flexWrap: 'wrap' }}>
				{products.map((product, index) => (
					<Card
						key={index}
						hoverable
						style={{ width:  340 }}
						cover={<img
							key={product.id}
							className="img"
							src={product.image.url}
							alt={product.name}
							onClick={() => setShowModal(index)}
							style={{
								Width: 320,
								Height: 320,
								margin: 0,
								cursor: 'pointer'
							}}
						/>}
					>
						<div style={{marginBottom: '10px'}}><strong style={{fontSize: '1.22em'}}>{ product.name }</strong></div>
						<div><strong>État : </strong>{ product.state }</div>
						<div><strong>Prix : </strong><span style={{fontSize: '1.2em'}}>{ product.price }</span> CHF TTC</div>
						<span>{ product.stock ? <Tag color="#87d068" > En Stock</Tag> : <Tag color="#a65c5c" style={{fontSize: '0.86em'}}> épuisé </Tag> }</span>
					</Card>

				))}
			</div>

			<Modal
				style={{minWidth: '74vw', height: '80vh'}}
				visible={showModal !== null}
				onCancel={() => setShowModal(null)}
				footer={null}
			>
				<ModalOccasion product={products[showModal]} />
			</Modal>
		</>
	);
};









/*
const OccassionList = ({value}) => {

  console.log(value)
  console.log("---")

  //Hooks
  const [itemSelected, setItemSelected] = useState(0)


  //Modal
  const [showModalHooksOccassion, setShowModalHooksOccassion] = useState(false)


  const showModalOccassion = (value) => {
    setItemSelected(value)
    setShowModalHooksOccassion(true)
  }

  const handleOccassionOk = e => {
    setShowModalHooksOccassion(false)
  }

  const handleOccassionCancel = e => {
    setShowModalHooksOccassion(false)
  }

  const eraseFiltres = (e) => {
    switch(e) {
      case 'state':
        value.filtres.state = false
        break
      case 'price':
        value.filtres.price = false
        break
      case 'brand':
        value.filtres.brand = false
        break
      case 'gender':
        value.filtres.gender = false
        break
    }
    console.log(value.filtres.state)

    if( value.filtres.state == false && value.filtres.price == false && value.filtres.brand == false && value.filtres.gender == false ) {
      value.filtres.filtreOn = false
    }
  }

  const state = <Tag  style={{fontSize: '1.2em', padding: '4px', marginLeft: '6px', marginRight: '6px'}} color="#a99569">Type <Icon type="close-circle" onClick={ () => eraseFiltres('state') }/></Tag>
  const price = <Tag  style={{fontSize: '1.2em', padding: '4px', marginLeft: '6px', marginRight: '6px'}} color="#a99569">Fourchette de prix <Icon type="close-circle" onClick={ () => eraseFiltres('price') }/></Tag>
  const brand = <Tag  style={{fontSize: '1.2em', padding: '4px', marginLeft: '6px', marginRight: '6px'}} color="#a99569">Marques <Icon type="close-circle" onClick={ () => eraseFiltres('brand') }/></Tag>
  const gender = <Tag  style={{fontSize: '1.2em', padding: '4px', marginLeft: '6px', marginRight: '6px'}} color="#a99569">Genre <Icon type="close-circle" onClick={ () => eraseFiltres('gender') }/></Tag>


  return(
  <>

    { value.filtres.filtreOn ?
    <div style={{display: 'flex', justifyContent: 'center', margin: '20px'}}>
      <div className="search-barre" style={{minHeigh: '10px'}}> <strong>Filtres :</strong>
        <div>
          { value.filtres.state ? state : '' }
          { value.filtres.price ? price : '' }
          { value.filtres.brand ? brand : '' }
          { value.filtres.gender ? gender : '' }
        </div>
      </div>
    </div>
   : '' }
    <div style={{ margin: '10px', padding: '10px'}}>
      {value.data.items.map((value, index) => (
        <img key={value.id} style={{cursor: 'pointer', maxWidth: '10vw'}} className="img" src={value.img} alt={value.alt} onClick={ () => showModalOccassion(index) }/>
      ))}
    </div>

    <Modal
      style={{minWidth: '64vw', height: '80vh'}}
      visible={ showModalHooksOccassion }
      onOk={ handleOccassionOk }
      onCancel={ handleOccassionCancel }
      okButtonProps={{ style: { display: 'none' } }}
    >
      <ModalOccasion value_i={value.data.items[itemSelected]}/>
    </Modal>
  </>
  )
}
*/
export default OccassionList
